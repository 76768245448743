import { DEL_ITEM_PERMISSION, MAINTAIN_PERMISSION, WEB_ANALYTICS_PPVF_FIX_PO } from "@kraftheinz/common";
import { M as MyUtil } from "./MyUtil.js";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
import "lodash";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", { attrs: { "title": "Maintain and Fix POs", "has-active": false, "has-filter-inactive": false, "create-button-text": "Add New" }, scopedSlots: _vm._u([{ key: "customFilter", fn: function() {
    return [_c("div", [_c("div", { staticClass: "d-flex align-items-center" }, [_c("span", { staticClass: "d-flex mr-2 align-items-center h-100" }, [_vm._v("Show Pos to fix for Country")]), _c("div", { staticClass: "country-filter mr-2" }, [_c("select-filter", { key: "Country", attrs: { "source": "countryId", "placeholder": "Country", "data-source": _vm.countries, "source-label": _vm.generateCountryLabel, "value": _vm.selectedCountry, "default-active-first-option": "" }, on: { "change": function($event) {
      return _vm.onSelectFilterChange($event, "CountryID");
    } } })], 1)]), _c("div", { staticClass: "mt-2" }, [_vm._v(" Note: Purchase Orders should also be fixed in BPCS where-ever possible. ")])])];
  }, proxy: true }, { key: "action", fn: function(ref) {
    var edit = ref.edit;
    var record = ref.record;
    return [_vm.can(_vm.permissions.maintain) ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Edit")];
    }, proxy: true }], null, true) }, [_c("a-button", { staticClass: "mr-2", attrs: { "icon": "edit", "size": "small", "type": "link" }, on: { "click": function($event) {
      return edit(record);
    } } })], 1) : _vm._e(), _vm.can(_vm.permissions.del) ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Delete")];
    }, proxy: true }], null, true) }, [_c("a-button", { attrs: { "icon": "delete", "size": "small", "type": "link" }, on: { "click": function($event) {
      return _vm.onDelete(record);
    } } })], 1) : _vm._e()];
  } }]) }, [_c("text-field", { key: "PurchaseOrder", attrs: { "data-index": "purchaseOrder", "title": "Purchase Order" } }), _c("text-field", { key: "PurchaseOrderLine", attrs: { "data-index": "purchaseOrderLine", "title": "Purchase Order Line" } }), _c("text-field", { key: "Currency", attrs: { "data-index": "currency", "title": "Currency" } }), _c("text-field", { key: "Price", attrs: { "data-index": "price", "title": "Price" } }), _c("text-field", { key: "Quantity", attrs: { "data-index": "quantity", "title": "Quantity" } })], 1);
};
var staticRenderFns$1 = [];
var FixPo_vue_vue_type_style_index_0_scoped_true_lang = /* @__PURE__ */ (() => ".country-filter[data-v-24c35620]{min-width:160px}.procurement-filter[data-v-24c35620]{min-width:230px}\n")();
const __vue2_script$1 = {
  name: "FixPo",
  inject: ["crud", "can"],
  mixins: [MyUtil],
  data() {
    return {
      permissions: {
        del: DEL_ITEM_PERMISSION,
        maintain: MAINTAIN_PERMISSION
      }
    };
  },
  created() {
    this.getCountries(false);
    this.crud.setQueryString("CountryID", this.countryIdAU);
  },
  methods: {
    onDelete(record) {
      this.$confirm({
        class: "list__modal-delete",
        icon: "delete",
        title: `Do you want to delete Purchase Order?`,
        content: `If you click Yes, you won\u2019t be able to undo this Delete operation. Are you sure you want delete Purchase Order?`,
        cancelText: "No",
        okText: "Delete",
        okType: "danger",
        onOk: this.onConfirmDelete(record)
      });
    },
    onConfirmDelete(record) {
      return () => {
        this.axios.put(`${this.apiUrl}/web-analytics/input-fix-po`, {
          CountryID: record.countryID,
          Currency: record.currency,
          Price: record.price,
          PurchaseOrder: record.purchaseOrder,
          PurchaseOrderLine: record.purchaseOrderLine,
          Quantity: record.quantity,
          UpdateType: "delete"
        }).then(() => {
          this.crud.fetchList();
          this.$notification["success"]({
            message: "Item Deleted!"
          });
        }).catch(() => {
          this.$notification["error"]({
            message: "Something went wrong, please try again!"
          });
        });
      };
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "24c35620", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var FixPo = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "name": "web-analytics.input-fix-po", "api-url": _vm.apiUrl, "create-route": "/web-analytics/fix-po/create", "edit-route": "/web-analytics/fix-po/:id", "page": _vm.page } }, [_c("fix-po")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    FixPo
  },
  data() {
    return {
      page: WEB_ANALYTICS_PPVF_FIX_PO,
      FixPo,
      apiUrl,
      itemsMenu: [
        {
          key: "ppv-forecast",
          title: "PPV-Forecast",
          path: ""
        },
        {
          key: "fix-po",
          title: "Maintain and Fix POs",
          path: "/web-analytics/fix-po"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
